/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Volunteer State is home to a large community of individuals with hearing loss, many of whom can be helped with the right hearing aid (although a surprisingly large number fail to do so.) At hear.com, we want to change that. Tennessee is a state where hearing matters. Whether it’s enjoying country music in Nashville or Dollywood, or having conversations with friends or strangers in one of America’s most hospitable states, hearing is vital to the lives of Tennessee residents. And it’s also essential in professional arenas – from sawmills and distilleries, to financial service companies and construction sites."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "major-hearing-loss-hazards-in-tennessee",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#major-hearing-loss-hazards-in-tennessee",
    "aria-label": "major hearing loss hazards in tennessee permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Major Hearing Loss Hazards in Tennessee"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most of the time, Tennesseans don’t realize that they are putting their hearing at risk. Hearing loss tends to develop over time, often over decades, and sometimes, it’s the things we love doing that are the root cause. For instance, live music can be devastating for our hearing. Amplified performances can create 120db of noise, well above the 80db experts suggest is the threshold for causing auditory damage. Automobiles and trucks can be just as harmful. Cities like Nashville and Knoxville have neighborhoods that rank among America’s noisiest – generating a steady hum which often exceeds that 80db limit."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you live near places like Nashville International Airport, that can add another layer of noise. However, even rural settings can be dangerous for your hearing. Hunting is hugely popular across the Volunteer State, but putting on protective equipment is less common. One study found that 95% of American hunters had gone without hearing protection at least once in the past year. With gunshots generating 140db of noise, just one shot can permanently damage your hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Finally, from the state’s famous distilleries to its coal mines, airports, and military bases, workers across Tennessee are exposed to high noise levels every day, often without knowing the risks. This all adds up to a large number of people with some degree of hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "understanding-hearing-loss-in-tennessee-a-look-at-the-statistics",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-hearing-loss-in-tennessee-a-look-at-the-statistics",
    "aria-label": "understanding hearing loss in tennessee a look at the statistics permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding Hearing Loss in Tennessee: A Look at the Statistics"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To understand Tennessee’s hearing loss situation, it helps to think about a few key statistics. Tennessee has a population of around 6.7 million people. 20% of American adults have some degree of hearing loss, which could add up to as many as 1.2 million Tennesseans. That’s a lot of people, and not all of them are seniors. Around 14% of those under the age of 69 report some level of hearing loss. Therefore, anyone can be vulnerable to hearing loss, although it’s true that those over the age of 60 are more at risk. What’s surprising, however, is how few Americans choose to wear hearing aids. The NIH reports that 16% of Americans between the ages of 20-69 who would benefit from wearing hearing aids actually do so."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discuss-your-hearing-loss-problems-in-tennessee-with-a-hearcom-expert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discuss-your-hearing-loss-problems-in-tennessee-with-a-hearcom-expert",
    "aria-label": "discuss your hearing loss problems in tennessee with a hearcom expert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discuss Your Hearing Loss Problems in Tennessee with a hear.com Expert"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re one of the many Tennessee residents with some form of hearing loss, why tolerate a lower quality of life? Modern hearing aids can restore your quality of life through better hearing! With high-tech features like wireless streaming and smartphone connectivity, hearing aids are more flexible and powerful than ever. And hear.com makes it easy for you to find your perfect hearing aid. We’ve established partnerships with the world’s leading hearing aid manufacturers, while also creating an in-state network of over 80 trusted Partner Providers (audiologists and hearing care specialists)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-your-perfect-hearing-aid-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-your-perfect-hearing-aid-with-hearcom",
    "aria-label": "find your perfect hearing aid with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find Your Perfect Hearing Aid with hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we believe in the value of seeking help for hearing loss. You don’t have to struggle to hear your friends or co-workers any more. Simply fill out the questionnaire below and you’ll have a free, personal phone consultation with a hear.com hearing aid expert. And in no time, you’ll be hearing like the good old days!"), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
